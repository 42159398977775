import { z } from "zod";

export const scoreChains = [
  "polygon",
  "optimism",
  "solana",
  "avalanche",
  "base",
  "aptos",
  "axelar",
  "kaia",
  "sei",
  "near",
  "thorchain",
  "arbitrum",
  "ethereum",
  "flow",
  "blast",
] as const;

export const scoreChainSchema = z.enum(scoreChains, {
  errorMap: () => {
    return { message: "invalid chain" };
  },
});

export const scoreBaseSchema = z.object({
  blockchain: z.string(),
  totalScore: z.number(),
  address: z.string(),
  calculationTime: z.coerce.date(),
});

export const fullScoreSchema = scoreBaseSchema.extend({
  id: z.string(),
  defiScore: z.number(),
  activityScore: z.number(),
  tokensScore: z.number(),
  nftsScore: z.number(),
  airdropsScore: z.number(),
  specialScore: z.number(),
  govScore: z.number(),
});

export const scoreHistoryItemSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  userId: z.string(),
  address: z.string(),
  score: z.number(),
  chain: z.string(),
  bucketId: z.string().nullable(),
  questId: z.string().nullable(),
  profileId: z.string().nullable(),
  sessionId: z.string().nullable(),
  subScores: fullScoreSchema.omit({ address: true }).extend({ userAddress: z.string() }),
});

export const scoreHistorySchema = z.array(scoreHistoryItemSchema);

export const addressScoreSchema = z.object({
  score: fullScoreSchema.extend({ userAddress: z.string() }),
  history: scoreHistorySchema.nullable().optional(),
});

export const userScoreSchema = z.array(addressScoreSchema);

const snowflakeScoreSchema = z.object({
  tokensScore: z.number(),
  totalScore: z.number(),
  govScore: z.number(),
  activityScore: z.number(),
  address: z.string(),
  blockchain: z.string(),
  scoreVersion: z.coerce.number(),
  nftsScore: z.number(),
  defiScore: z.number(),
  scoreDate: z.coerce.date(),
});

export const cacheKeyForChainAndAddress = (tableName: string, address: string) => {
  return `scores:${tableName.toLowerCase()}:${address}`;
};

export type ScoreHistory = z.infer<typeof scoreHistorySchema>;
export type Score = z.infer<typeof fullScoreSchema>;
export type UserScore = z.infer<typeof userScoreSchema>;
export type AddressScore = z.infer<typeof addressScoreSchema>;
export type ScoreHistoryItem = z.infer<typeof scoreHistoryItemSchema>;
export type ScoreBase = z.infer<typeof scoreBaseSchema>;
export type ScoreChain = z.infer<typeof scoreChainSchema>;
export type SnowflakeScore = z.infer<typeof snowflakeScoreSchema>;
